<template>
  <div>
    <v-row>
      <v-col>
        <v-card class="mx-auto" outlined>
          <v-card-text>
            <v-row>
              <v-col>
                <multiselect v-model="publisher" :options="publishers_list" label="name" track-by="id"
                             @select="onSelect" placeholder="Select Publisher">
                  <span slot="noResult">Oops! No publisher with the name. Consider changing the search query.</span>
                </multiselect>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="search.dateFrom"
                        label="Date From"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="search.dateFrom"
                      :min="formatDate(new Date(), availableDate)"
                      :max="formatDate(new Date())"
                      @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
<!--                <input type="date" id="dateFrom" v-model="search.dateFrom" class="form-control"-->
<!--                       :min="formatDate(new Date(), 60)"-->
<!--                       :max="formatDate(new Date())"-->
<!--                       placeholder="Select Date From">-->
              </v-col>
              <v-col>
                <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="search.dateTo"
                        label="Date To"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="search.dateTo"
                      :min="formatDate(new Date(), availableDate)"
                      :max="formatDate(new Date())"
                      @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="trackPublisher" color="#4CAF50" dark>Update</v-btn>
            <v-btn @click="reset" color="#2196F3" dark>Reset</v-btn>
          </v-card-actions>
          <v-row>
            <v-col>
              <div class="text-center" style="margin-bottom: 50px" v-if="report_loading">
                <v-progress-circular
                    :size="50"
                    indeterminate
                    color="primary"
                ></v-progress-circular>
              </div>
              <TrackingReport v-else :date-from="search.dateFrom" :date-to="search.dateTo" :publisher="publisher"/>
            </v-col>
          </v-row>

        </v-card>

      </v-col>
    </v-row>
    <!--    <v-row>-->
    <!--      <v-col>-->
    <!--        <v-card outlined>-->
    <!--          <v-card-title>All Time Data</v-card-title>-->
    <!--          <v-card-text>-->
    <!--            <v-row cols="12" sm="8" md="8">-->
    <!--              <v-col>-->
    <!--                <v-card outlined>-->
    <!--                  <v-card-title>Total Articles</v-card-title>-->
    <!--                  <v-card-text>-->
    <!--                    <h3 class="display-2 text-center">-->
    <!--                      <strong> {{all_time_loading ? 'Loading..' : format_numbers(all_time.articles) }} </strong>-->
    <!--                    </h3>-->
    <!--                  </v-card-text>-->
    <!--                </v-card>-->
    <!--              </v-col>-->
    <!--              <v-col>-->
    <!--                <v-card outlined>-->
    <!--                  <v-card-title>Total Views</v-card-title>-->
    <!--                  <v-card-text>-->
    <!--                    <h3 class="display-2 text-center">-->
    <!--                      <strong> {{all_time_loading ? 'Loading..' : format_numbers(all_time.views) }} </strong>-->
    <!--                    </h3>-->
    <!--                  </v-card-text>-->
    <!--                </v-card>-->
    <!--              </v-col>-->
    <!--            </v-row>-->
    <!--            <v-row cols="12" sm="8" md="8">-->
    <!--              <v-col>-->
    <!--                <v-card outlined>-->
    <!--                  <v-card-title>Total Reactions</v-card-title>-->
    <!--                  <v-card-text>-->
    <!--                    <h3 class="display-2 text-center">-->
    <!--                      <strong> {{ all_time_loading ? 'Loading..' : format_numbers(all_time.reactions) }} </strong>-->
    <!--                    </h3>-->
    <!--                  </v-card-text>-->
    <!--                </v-card>-->
    <!--              </v-col>-->
    <!--              <v-col>-->
    <!--                <v-card outlined>-->
    <!--                  <v-card-title>Total Comments</v-card-title>-->
    <!--                  <v-card-text>-->
    <!--                    <h3 class="display-2 text-center">-->
    <!--                      <strong> {{all_time_loading ? 'Loading..' : format_numbers(all_time.comments) }} </strong>-->
    <!--                    </h3>-->
    <!--                  </v-card-text>-->
    <!--                </v-card>-->
    <!--              </v-col>-->
    <!--              <v-col>-->
    <!--                <v-card outlined>-->
    <!--                  <v-card-title>Total Shares</v-card-title>-->
    <!--                  <v-card-text>-->
    <!--                    <h3 class="display-2 text-center">-->
    <!--                      <strong> {{all_time_loading ? 'Loading..' : format_numbers(all_time.shares) }} </strong>-->
    <!--                    </h3>-->
    <!--                  </v-card-text>-->
    <!--                </v-card>-->
    <!--              </v-col>-->
    <!--            </v-row>-->
    <!--          </v-card-text>-->
    <!--        </v-card>-->
    <!--      </v-col>-->
    <!--    </v-row>-->

    <v-row>
      <v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import TrackingReport from "./TrackingReport";
import numeral from "numeral";

export default {
  name: "TrackPublisher",
  components: {TrackingReport, Multiselect},
  data() {
    return {
      menu1 : false,
      menu2 : false,
      search: {
        publisher_ids: [],
        dateFrom: '',
        dateTo: '',
      },
      publisher: {},
      lastSelected: {},
      availableDate: 59,
    }
  },
  computed: {
    publishers_list() {
      return this.$store.getters.getUserPublishers
    },
    report_loading() {
      return this.$store.getters.getReportLoading
    },
    report() {
      return this.$store.getters.getTracking
    },
    all_time_loading() {
      return this.$store.getters.getAllTimeLoading
    },
    all_time: {
      get() {
        return this.$store.getters.getAllTime
      },
      set(newVal) {
        console.log(newVal)
        return this.$store.getters.getAllTime
      }
    }
  },
  created() {
    this.initial()
  },
  methods: {
    formatDate(date, subDays = null) {
      let dd = 0
      let mm = 0
      let yy = 0
      if (subDays == null) {
        dd = date.getDate()
        if (dd < 10) {
          dd = '0' + dd
        }
        mm = date.getMonth() + 1
        if (mm < 10) {
          mm = '0' + mm
        }
        yy = date.getFullYear()
      } else {
        date.setDate(date.getDate() - subDays);
        dd = date.getDate()
        if (dd < 10) {
          dd = '0' + dd
        }
        mm = date.getMonth() + 1
        if (mm < 10) {
          mm = '0' + mm
        }
        yy = date.getFullYear()
      }
      return yy + '-' + mm + '-' + dd
    },
    format_numbers(val) {
      if (val.toString().length > 6) {
        return numeral(val).format('0.0a')
      } else {
        return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }
    },
    initial() {
      this.publisher = this.publishers_list[0]
      this.search.dateFrom = this.formatDate(new Date(), 7)
      this.search.dateTo = this.formatDate(new Date(), 1)
      this.trackPublisher()
      this.availableDate = this.isAdmin() ? 89 : 59
      
    },
    trackPublisher() {
      this.search.publisher_ids = []
      this.search.publisher_ids.push(this.publisher.id)
      if (this.search.publisher_ids) {
        this.$store.dispatch('trackAllTimeReport', this.search)
        this.$store.dispatch('trackTimeSeriesReport', this.search)
      } else {
        alert("Select a publisher and valid dates to view report")
      }
    },
    onSelect(item, last) {
      this.publisher = item
      this.lastSelected = last
      this.trackPublisher()
    },
    reset() {
      this.search.dateFrom = this.formatDate(new Date(), 7)
      this.search.dateTo = this.formatDate(new Date(), 1)
      this.trackPublisher()
    },
    checkDates() {
      let safe = true;
      let from = new Date(this.search.dateFrom).getTime()
      let to = new Date(this.search.dateTo).getTime()
      let base = to - from
      let diff = base / (1000 * 3600 * 24)
      if (diff > 31) {
        safe = false
      }
      return safe
    },
    isAdmin() {
      return this.$store.getters.getIsAdmin;
    },

  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>

</style>